// Contextual backgrounds
@mixin bg-variant($parent, $color, $ignore-warning: true) {
    #{$parent} {
        //background: linear-gradient(87deg, $color 100%, adjust-hue($color, 0%) 100%) !important;
        background: linear-gradient(180deg, #F2EDD7FF 25%, adjust-hue(#FDD20EFF, 00%) 100%) !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background-color: darken($color, 10%) !important;
        }
    }
}

@mixin bg-gradient-variant($parent, $color) {
    #{$parent} {
        background: linear-gradient(87deg, $color 0, adjust-hue($color, 50%) 100%) !important;
    }
}

@mixin bg-translucent-variant($parent, $color) {
    #{$parent} {
        background-color: darken(rgba($color, $translucent-color-opacity), 7%) !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background-color: darken(rgba($color, $translucent-color-opacity), 12%) !important;
        }
    }
}
